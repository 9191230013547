@import "../../../../vendor/acc-digital/pim-websitebundle/bundles/AccWebsiteBundle/Resources/views/components/GdprConsent/GdprConsent.scss";

:root {
    --cc-primary-color: #0095d9;
    --cc-secondary-color: #0095d9;
    --cc-text-color: #353334;
    --cc-text-color-headline: #353334;
    --cc-modal-box-background: #efefef;
    --cc-border-radius: 0.125rem;
    --cc-border-color: #dbdbdb;
    --cc-switch-bg-inactive: #c7c7c7;
    --cc-background: rgba(0, 0, 0, 0.4);
    --cc-btn-primary-hover-bg: #046ab0;
    --cc-btn-secondary-hover-bg: #046ab0;
    --cc-btn-default-hover-bg: #94d9f8;
}

.consent-wrapper {
    @media (max-width: theme("screens.md")) {
        left: 50% !important;
        top: 70px !important;
        transform: translateX(-50%) !important;
        bottom: 70px !important;
    }

    .script-list {
        padding: 0;

        .script-item-btn {
            padding-left: 1rem;
            padding-right: 1rem;
            border-left: 0;
            border-top: 0;
            border-right: 0;
            box-shadow: none;
        }
    }
}

.luf-footer{
    .GdprToggle{
        all: inherit;
        color: #0095d9;
        background-color: transparent;
        cursor: pointer;
        transition: color 300ms ease-in-out;

        &:hover{
            color: #00608d;
        }
    }
}

.gdpr {
    &-element {
        width: 100%;
        height: 100%;
    }

    &-wrap {
        width: 100%;
        height: 100%;
    }

    &-text {
        width: 100%;
        height: 100%;

        display: flex !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        background: gray;

        border: 1px solid #0095d9;
        padding: 1rem;

        .title {
            font-size: 24px;
            font-weight: bold;
            color: white;
        }

        .description {
            padding: 1rem;
        }

        .button {
            background: #0095d9;
        }
    }
}
